import React from "react";
import "./WhyUs.css";

const WhyUsCard = (props) => {
    return (
        <div className="why-us-card why-us-card-container">
            <img className="card-image" src={props.image} alt={props.alt} />
            <h4 className="title-6 bold card-number">0{props.number}</h4>
            <h3 className="title-6 card-title">{props.title}</h3>
            <p
                className="p-2 card-description"
                dangerouslySetInnerHTML={{ __html: props.description }}></p>
        </div>
    );
};

export default WhyUsCard;
