import React from "react";
import "./Pricing.css";
import PricingCard1 from "./PricingCard1";
import PricingCardTwo from "./PricingCardTwo";
import PricingCardThree from "./PricingCardThree";
import ReferAFriend from "./ReferAFriend";

const Pricing = () => {
    return (
        <div className="pricing pricing-container">
            <h2 className="title-2 pricing-title">We're ready, are you?</h2>
            <h3 className="title-5 pricing-subtitle">
                Pick the plan that best suits your project
            </h3>
            <div className="pricing-cards-container">
                <PricingCard1 />
                <PricingCardTwo />
                <PricingCardThree />
            </div>
            <ReferAFriend />
        </div>
    );
};

export default Pricing;
