import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination , EffectFade } from "swiper/modules";
import BulletIcon from "./assets/bullet.svg";
import "swiper/css";
import "swiper/css/pagination";

import img1 from "../../images/sample/1.jpg";
import img2 from "../../images/sample/2.jpg";
import img3 from "../../images/sample/3.jpg";
import img4 from "../../images/sample/4.jpg";
import img5 from "../../images/sample/5.jpg";
import img6 from "../../images/sample/6.jpg";
import img7 from "../../images/sample/7.jpg";
import img8 from "../../images/sample/8.jpg";
import img9 from "../../images/sample/9.jpg";
import img10 from "../../images/sample/10.jpg";

const ServicesCards = () => {
  const cardData = [
    {
      title: "Branding",
      items: ["Logo", "Brand guides", "Stationery", "Brand strategy"],
      banner: [img6, img1, img10, img7, img5],
    },
    {
      title: "UI/UX",
      items: [
        "Website/app",
        "Design systems",
        "No-code frontend",
        "Design audits",
      ],
      banner: [img3, img4, img9, img2, img8],
    },
    {
      title: "Product Packaging",
      items: [
        "Product labels",
        "Food packaging",
        "Subscription boxes",
        "Copywriting",
      ],
      banner: [img1, img10, img7, img5, img6],
    },
    {
      title: "Marketing",
      items: [
        "Marketing",
        "Email graphics",
        "Advertisement design",
        "Brand strategy",
      ],
      banner: [img3, img4, img9, img2, img8],
    },
  ];

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      breakpoints={{
        768: {
          slidesPerView: 2.2,
          spaceBetween: 30,
        },
      }}
      className="services-cards services-cards-container swiper"
    >
      {cardData.map((card, index) => (
        <SwiperSlide key={index}>
          <div className={`card card-${index + 1}`}>
              <img className="image" src={card.banner[0]} alt={`banner`} />
            <div className="active-bar"></div>
            <div className="info">
              <h3 className="title-5">{card.title}</h3>
              {card.items.map((item, itemIndex) => (
                <p key={itemIndex} className="body-1">
                  <img className="bullet" src={BulletIcon} alt="bullet" />
                  {item}
                </p>
              ))}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ServicesCards;
