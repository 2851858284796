import React from "react";
import "./Footer.css";
import Icon1 from "./assets/icon_1.svg";
import Icon2 from "./assets/icon_2.svg";
import Icon3 from "./assets/icon_3.svg";
import bottomImg from '../../images/aster.png'


const Footer = () => {
    const socialIcons = [
        {
            link: "https://google.com",
            image: Icon1,
            alt: "BE Icon",
        },
        {
            link: "https://google.com",
            image: Icon2,
            alt: "LinkedIn Icon",
        },
        {
            link: "https://google.com",
            image: Icon3,
            alt: "Instagram Icon",
        },
    ];

    const footerMenue = [
        {
            link: "https://google.com",
            text: "© THE HOUSE OF ASTER 2024",
        },
        {
            link: "https://google.com",
            text: "TERMS OF SERVICE",
        },
        {
            link: "https://google.com",
            text: "PRIVACY POLICY",
        },
        {
            link: "https://google.com",
            text: "CAREERS",
        },
        {
            link: "https://google.com",
            text: "ABOUT US",
        },
        {
            link: "mailto:hello@thehouseofaster.com",
            text: "hello@thehouseofaster.com",
        },
    ];

    return (
        <div className="footer footer-container">
            <div className="footer-row-1">
                {socialIcons.map((icon, index) => (
                    <a
                        href={icon.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}>
                        <img
                            className="row-1-icon"
                            src={icon.image}
                            alt={icon.alt}
                        />
                    </a>
                ))}
            </div>
            <div className="footer-row-2">
                {footerMenue.map((obj, index) => (
                    <a
                        className="row-2-text body-1"
                        href={obj.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}>
                        {obj.text}
                    </a>
                ))}
            </div>

        </div>
    );
};

export default Footer;
