import React from 'react'
import "./BookACall.css"
import { ButtonPrimarySecond } from "../Buttons/PrimaryButton";
import bottomImg from '../../images/aster.png'

const BookACall = () => {
  return (
    <div className="BAC">
      <div className='book-a-call book-a-call-container'>
        <h2 className='title-1'>We’re better in person!</h2>
        <ButtonPrimarySecond text="Work with us" small />
      </div>
    </div>
  )
}

export default BookACall