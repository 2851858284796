import React from "react";
import ClientsLogos from "../components/ClientsLogos/ClientsLogos";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import Services from "../components/Services/Services";
import WithUs from "../components/WithUs/WithUs";
import Header from "../components/Header/Header";
import ClientsProblems from "../components/ClientsProblems/ClientsProblems";
import Pricing from "../components/Pricing/Pricing";
import WhyUs from "../components/WhyUs/WhyUs";
import BookACall from "../components/BookACall/BookACall";
import Footer from "../components/Footer/Footer";
import Testimonial from "../components/Testimonial/Testimonial";
import Challenges from "../components/Challenges/Challenges";
import FAQ from "../components/FAQ/FAQ";
import Cursor from "../components/Cursor/Cursor";
import { useState } from "react";
import cursorStore from "../stores/cursorStore";
import deviceStore from '../stores/deviceStore';
import { observer } from "mobx-react-lite";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import BottomImage from '../images/aster.png'
import "../stylesheet/home.css"


const Home = observer(() => {
    return (
        <div className="home-page">
            
            <Header />
            <HeroBanner />
            <VideoPlayer />
            <ClientsLogos />
            <Services />
            <Challenges />
            <WithUs />
            <ClientsProblems />
            <WhyUs />
            <Pricing />
            <Testimonial />
            <FAQ />
            <BookACall/>
            <Footer/>
            <Cursor/>
            <img src={BottomImage} alt="aster" className='home-bottom-croc-img' />
        </div>
    );
});

export default Home;
