import React, { useEffect, useRef } from "react";
import "./Cursor.css";
import gsap from "gsap";
import cursorStore from "../../stores/cursorStore";
import { observer } from "mobx-react-lite";
import nextIcon from "./assets/nextIcon.svg";
import prevIcon from "./assets/prevIcon.svg";

const Cursor = observer(() => {
    const mouse = useRef({ x: 0, y: 0 });
    const delayedMouse = useRef({ x: 0, y: 0 });
    const rafId = useRef(null);
    const circle = useRef();
    const size = cursorStore.isActive ? cursorStore.activeSize : 30;

    const lerp = (x, y, a) => x * (1 - a) + y * a;

    const manageMouseMove = (e) => {
        const { clientX, clientY } = e;
        mouse.current = {
            x: clientX,
            y: clientY,
        };
    };

    const animate = () => {
        const { x, y } = delayedMouse.current;
        delayedMouse.current = {
            x: lerp(x, mouse.current.x, 0.175),
            y: lerp(y, mouse.current.y, 0.175),
        };
        moveCircle(delayedMouse.current.x, delayedMouse.current.y);
        rafId.current = window.requestAnimationFrame(animate);
    };

    const moveCircle = (x, y) => {
        gsap.set(circle.current, { x, y, xPercent: -50, yPercent: -50 });
    };

    useEffect(() => {
        animate();
        window.addEventListener("mousemove", manageMouseMove);
        return () => {
            window.removeEventListener("mousemove", manageMouseMove);
            window.cancelAnimationFrame(rafId.current);
        };
    }, [cursorStore.isActive]);

    return (
        <div
            className="cursor-container"
            style={{ display: cursorStore.visible ? "block" : "none" }}>
            <div
                style={{
                    width: size,
                    height: size,
                }}
                className={`cursor-circle ${
                    cursorStore.type === "video"
                        ? "cursor-circle-video"
                        : cursorStore.type === "slider"
                        ? "cursor-circle-slider"
                        : ""
                }`}
                ref={circle}>
                {cursorStore.type === "video" ? (
                    <p className="body-1">
                        {cursorStore.videoPlaying ? "Pause" : "Play"}
                    </p>
                ) : cursorStore.type === "slider" ? (
                    <img
                        src={
                            cursorStore.cursorText === "next"
                                ? nextIcon
                                : prevIcon
                        }
                        width={"36px"}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
});

export default Cursor;
