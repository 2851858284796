import React, { useState, useEffect } from "react";
import Card1Image from "./assets/Comp_4.json";
import Card2Image from "./assets/Comp_2.json";
import Card3Image from "./assets/Comp_3.json";
import "./WithUs.css";
import WithUsCard from "./WithUsCard";

const WithUsCards = () => {
    const [activeCardIndex, setActiveCardIndex] = useState(0);

    const cardData = [
        {
            title: "Talk to us",
            description:
                "We start by listening. Our founders will personally join a call with you to capture your vision and understand your goals. This is a no-pressure <b>design consultation</b>, where we guide you toward success through design. You can then decide whether we’re the right fit for your brand! ",
            image: Card1Image,
            alt: "Circular graphic with profile icons"
        },
        {
            title: "Receive your designs",
            description:
                "We kickstart our collaboration with a <b>one-off contract.</b> We figure out exactly what we can do to meet your brand’s goals through design, and then we start immediately! We deliver designs that you’ll love, and so will your customers!",
            image: Card2Image,
            alt: "Circular graphic with profile icons"
        },
        {
            title: "Work with us long-term",
            description:
                "As your brand grows, so do its design needs. If our work impresses you, you can transition to a <b>subscription</b> model. Our team of talented designers will have your back, ready to design what you want, when you want it.",
            image: Card3Image,
            alt: "Circular graphic with profile icons"
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveCardIndex((prevIndex) => (prevIndex + 1) % cardData.length);
        }, 4000); // Change animation every 3 seconds

        return () => clearInterval(interval);
    }, [cardData.length]);

    return (
        <div className="with-us-cards with-us-cards-container">
            {cardData.map((card, index) => (
                <WithUsCard
                    key={index}
                    title={card.title}
                    description={card.description}
                    image={card.image}
                    alt={card.alt}
                    isActive={index === activeCardIndex}
                />
            ))}
        </div>
    );
};

export default WithUsCards;
