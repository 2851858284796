import React, { useState, useEffect } from "react";
import Tickmark from "./assets/tickmark.svg";
import { ButtonPrimarySecond } from "../Buttons/PrimaryButton";
import CardOneSeperator from "./assets/card-one-seperator.svg";

const cardCheckmarkTexts = [
    "Brand introduction meet",
    "Design consultation",
    "Customized package",
    "Dedicated Project Team",
    "Fast execution",
];

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
};

const PricingCard1 = () => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth > 768 && windowWidth < 953; // Adjust this breakpoint as needed

    return (
        <div className="card-one-container">
            <h3 className="title-3 card-one-title">One-Off Contract</h3>
            <p className="p-2">Ideal for first-time clients</p>
            <p className="p-2 card-subtitle">
                Try us before committing long term!
            </p>
            <img
                src={CardOneSeperator}
                alt="Seperator"
                className="stroke"
            />
            <div className="pricing-card-feature-list">
                {cardCheckmarkTexts.map((checkmarkText, index) => (
                    <div className="pricing-card-feature" key={index}>
                        <img
                            className="pricing-card-tickmark"
                            src={Tickmark}
                            alt="checkmark"
                        />
                        <p className="p-3">{checkmarkText}</p>
                    </div>
                ))}
            </div>
            {isMobile ? (
                <div className="pricing-card-one-button-wrapper">
                    <ButtonPrimarySecond text={"Get Started"} />
                </div>
            ) : (
                <ButtonPrimarySecond text={"Get Started"} />
            )}
        </div>
    );
};

export default PricingCard1;
