import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import CareersHero from "../components/CareersHero/CareersHero";
import OpenPositions from "../components/OpenPositions/OpenPositions";
import AlwaysHiring from '../components/AlwaysHiring/AlwaysHiring';
import { observer } from "mobx-react-lite";
import Cursor from "../components/Cursor/Cursor";
import HouseCulture from "../components/HouseCulture/HouseCulture";

const Careers = observer(() => {
    return (
        <>
            <Header />
            <CareersHero/>
            <HouseCulture />
            <OpenPositions />
            <AlwaysHiring />
            <Footer />
            <Cursor/>
        </>
    );
});

export default Careers;
