import React from "react";
import "./ClientsProblems.css";
import OpenLogo from "./assets/open.svg";
import cursorStore from "../../stores/cursorStore";
import { observer } from "mobx-react-lite";

const ClientsProblemsCard = observer((props) => {
    return (
        <div
            className="clients-problems-card clients-problems-card-container"
            key={props.key}
            style={{
                opacity: props.opacity,
                transition: "opacity 0.3s ease",
            }}
            onMouseOver={() => {
                cursorStore.setIsActive(true);
                cursorStore.setActiveSize(0);
            }}
            onMouseLeave={() => {
                cursorStore.setIsActive(false);
                cursorStore.setType("normal");
            }}>
            <img className="image" src={props.image} alt={props.alt} />
            <div className="clients-problems-card-box">
                <div className="clients-problems-card-inner-container">
                    <h4 className="text title-6">{props.title}</h4>
                    <h5 className="sub-text title-7">{props.subtitle}</h5>
                </div>
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    <img className="logo" src={OpenLogo} alt="Open" />
                </a>
            </div>
        </div>
    );
});

export default ClientsProblemsCard;
