import React, { useState } from 'react';
import { Button } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Keyframes for the growing circle effect
const growCircle = keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  50% {
    width: 200px;
    height: 200px;
    opacity: 0.2;
  }
  100% {
    width: 400px;
    height: 400px;
    opacity: 0;
  }
`;

// Custom styled buttons
const StyledButton = styled(Button)`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  padding: 12px 24px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.14);
  margin:0;
  background: linear-gradient(
    180deg,
    rgba(115, 8, 14, 0.00) 50%,
    rgba(115, 8, 14, 0.45) 100%
  ),
  var(--color-primary);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white; 
  transition: all 3000ms ease-in-out;
  font-family: var(--font-family-2), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: -0.16px; 
  text-transform: capitalize !important;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: linear-gradient(
      180deg,
      rgba(253, 207, 171, 0.00) 50%,
      rgba(253, 207, 171, 0.38) 100%
    ),
    var(--color-primary, #F15A2D);
    box-shadow: 0px 2px 20px 0px rgba(241, 90, 45, 0.50);
  }
  &.small {
    padding: 12px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.14px; 
    text-transform: capitalize !important;
  }

  &.clicked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: ${growCircle} 1s forwards;
  }

  &:disabled {
    background: #333;
    color: #808080;
    pointer-events: none; /* Disables pointer events */
  }
`;

// External CSS for arrow rotation on hover
const arrowRotation = `
  .rotate-on-hover:hover .MuiSvgIcon-root {
      transform: rotate(-45deg);
  }

  .rotate-on-hover .MuiSvgIcon-root {
    transition: transform 300ms ease;
  }
`;

// Inject external CSS
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = arrowRotation;
document.head.appendChild(styleSheet);

// Button with just text and external arrow
function ButtonPrimaryFirst({ text, small, disabled }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000); // Remove the class after the animation duration
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? 'clicked' : ''} ${small ? 'small' : ''} rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
}

// Button with text, external arrow to the right
function ButtonPrimarySecond({ text, small, disabled }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000); // Remove the class after the animation duration
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? 'clicked' : ''} ${small ? 'small' : ''} rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
      endIcon={<ArrowForwardIcon />}
    >
      {text}
    </StyledButton>
  );
}

// Button with external arrow to the right and then text
function ButtonPrimaryThird({ text, small, disabled }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000); // Remove the class after the animation duration
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? 'clicked' : ''} ${small ? 'small' : ''} rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
    >
      <ArrowForwardIcon /> {text}
    </StyledButton>
  );
}

// Button with just the arrow pointing top right
function ButtonPrimaryFourth({ small, disabled }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000); // Remove the class after the animation duration
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? 'clicked' : ''} ${small ? 'small' : ''} rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
    >
      <ArrowForwardIcon />
    </StyledButton>
  );
}

export { ButtonPrimaryFirst, ButtonPrimarySecond, ButtonPrimaryThird, ButtonPrimaryFourth };