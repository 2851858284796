import React, { useRef, useState, useEffect } from "react";
import "./Testimonial.css";
import sampleVideo from "./assets/sample_video.mp4";
import playBtn from "./assets/play-btn.svg";
import TestimonialBg from "./assets/background.svg";
import Isabella from "./assets/Isabella_Meadows.jpg";
import THWT from "./assets/THWT.svg";
import Keily from "./assets/Keily_black.jpg";
import MTS from "./assets/MTS.svg";
import Meson from "./assets/Mason_Domecq.png";
import Scoby from "./assets/Scoby.svg";
import cursorStore from "../../stores/cursorStore";
import { observer } from "mobx-react-lite";
import deviceStore from "../../stores/deviceStore";
import logo from "./assets/divini_logo.svg"

const Testimonial = observer(() => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch((error) => {
                    console.error("Error trying to play the video:", error);
                });
            }
            setIsPlaying(!isPlaying);
            cursorStore.setActiveSize(0);
        }
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.onplay = () => console.log("Video is playing");
            video.onpause = () => console.log("Video is paused");
            video.onerror = (e) => console.error("Video error:", e);
        }
    }, []);

    const testimonials = [
        {
            name: "Isabella Meadows",
            role: "Founder, The Hearts We Touch",
            image: Isabella,
            companyLogo: THWT,
            title: `"The House of Aster were integral to my success."`,
            message:
                "Embarking on my venture felt daunting until I met Mudit and the team. Their exceptional ability to transform my vision into a compelling brand identity was unparalleled. Mudit's personal encouragement and strategic insight were instrumental in my journey, making them more than partners.",
        },
        {
            name: "Kailey Black",
            role: "Founder, More Than Socials",
            image: Keily,
            companyLogo: MTS,
            title: `"Working with The House of Aster has been a great experience."`,
            message:
                "I am super grateful that I trusted them with my logo and in creating a professional brand identity. It was supposed to be the ‘Cherry on top’ of my business, but truly has become the foundation for me to take my business to new heights with EASE!",
        },
        {
            name: "Mason Domecq",
            role: "Founder, Scoby Drinks",
            image: Meson,
            companyLogo: Scoby,
            title: `"Amazing customer service, top tier design capability, and a young team full of energy and ambition."`,
            message:
                "Throughout the process of creating my brand, I was struggling to render my thoughts onto paper and went through several designers before meeting Mudit. THA developed the brand based on the mission and goals for the community I was looking to create. The team killed the first renditions and created an outstanding foundation to launch off of.",
        },
    ];

    return (
        <div className="testimonial">
            <img src={TestimonialBg} alt="" className="bgimg" />
            <h2 className="title-2 main-title">
                Here's what <span className="link-text">our clients</span> have
                to say
            </h2>
            <div className="main">
                <div
                    className="video-section"
                    onMouseOver={() => {
                        cursorStore.setVisible(true);
                        cursorStore.setIsActive(true);
                        const cursorSize =
                            deviceStore.deviceType === 1 ? 112 : 70;
                        cursorStore.setActiveSize(cursorSize);
                        cursorStore.setType("video");
                        cursorStore.setVideoPlaying(isPlaying);
                        cursorStore.setAction(togglePlay);
                    }}
                    onMouseLeave={() => {
                        cursorStore.setVisible(false);
                        cursorStore.setIsActive(false);
                        cursorStore.setType("normal");
                        cursorStore.setAction(null);
                    }}
                    onClick={togglePlay}>
                    <img
                        src={playBtn}
                        width={32}
                        height={32}
                        alt="Play button"
                        className={`playBtn mobile ${isPlaying ? 'hide' : ''}`}
                        onClick={togglePlay}
                        />
                    <video
                        ref={videoRef}
                        className="video"
                        src={sampleVideo}
                        loop
                        controls={false}
                        autoPlay={false}
                        muted={true}></video>
                    

                    <div className={`video-description-box ${isPlaying ? 'hide' : ''}`}>
                        <div className="video-description-box-inner-container">
                            <h4 className="text title-6">Mason Domecq</h4>
                            <h5 className="sub-text title-7">
                            Founder, DIVINI by Scoby Drinks
                            </h5>
                        </div>
                        <a
                            href="www.google.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img className="logo" src={logo} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className="cards">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="card">
                            <div className="ceo-img">
                                <img src={testimonial.image} alt="CEO" />
                            </div>
                            <div className="info-area">
                                <div className="ceo-info">
                                    <div className="info">
                                        <h3 className="body-1 name">
                                            {testimonial.name}
                                        </h3>
                                        <p className="body-2 role">
                                            {testimonial.role}
                                        </p>
                                    </div>
                                    <img
                                        src={testimonial.companyLogo}
                                        alt=""
                                        className="company-logo"
                                    />
                                </div>
                                <div className="summary">
                                    <p className="p-1 title">
                                        {testimonial.title}
                                    </p>
                                    <p className="p-2 message">
                                        {testimonial.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default Testimonial;
