import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import logo1 from "./assets/logo_1.svg";
import logo2 from "./assets/logo_2.svg";
import logo3 from "./assets/logo_3.svg";
import logo4 from "./assets/logo_4.svg";
import logo5 from "./assets/logo_5.svg";
import logo6 from "./assets/logo_6.svg";
import logo7 from "./assets/logo_7.svg";
import logo8 from "./assets/logo_8.svg";
import logo9 from "./assets/logo_9.svg";
import './ClientsLogos.css';

const ClientsLogosCarousel = () => {
  const containerRef = useRef(null);
  const railRef = useRef(null);
  const logosRef = useRef([]);
  const animationRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const rail = railRef.current;
    const logos = logosRef.current;
    let totalWidth = 0;

    logos.forEach((logo, index) => {
      totalWidth += logo.offsetWidth;
      if (index < logos.length - 1) totalWidth += 70; // Add gap
    });

    logos.forEach(logo => {
      const clone = logo.cloneNode(true);
      rail.appendChild(clone);
    });

    animationRef.current = gsap.to(rail, {
      x: -totalWidth,
      duration: 120,
      ease: "none",
      repeat: -1,
      paused: true,
    });

    animationRef.current.play();

    container.addEventListener('mouseenter', () => animationRef.current.pause());
    container.addEventListener('mouseleave', () => animationRef.current.play());

    return () => {
      if (animationRef.current) animationRef.current.kill();
    };
  }, []);

  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9];

  return (
    <div ref={containerRef} className="clients-logos-carousel clients-logos-carousel-container">
      <div className="fade-overlay fade-left" />
      <div className="fade-overlay fade-right" />
      <div ref={railRef} className="logo-rail">
        {Array(8).fill().map((_, i) => (
          logos.map((logo, index) => (
            <img
              key={`${index}-${i}`}
              ref={el => logosRef.current.push(el)}
              src={logo}
              alt={`client logo ${index + 1}`}
              className="logo"
            />
          ))
        ))}
      </div>
    </div>
  );
};

export default ClientsLogosCarousel;
