import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/logo.svg';
import { ReactComponent as BoldMenuIcon } from '../../images/icons/bold/bold-menu.svg';
import { ReactComponent as BoldCloseIcon } from '../../images/icons/bold/bold-close.svg';
import './Header.css';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuIcon = isOpen ? <BoldCloseIcon className="menu-icon" /> : <BoldMenuIcon className="menu-icon" />;

    return (
        <header className={isSticky ? 'sticky' : ''}>
            <div className={`header ${isSticky ? 'sticky' : ''}`}>
                <div className="logo">
                    <img src={logo} width={200} height={100} alt="The House of Aster" />
                </div>
                <nav className={isOpen ? 'open' : ''}>
                    <div className="header-summary">
                        <p className="title-7"><NavLink to="/">We</NavLink> do awesome <NavLink to="/">work</NavLink> with these <NavLink to="/">services</NavLink> at these <NavLink to="/">prices</NavLink>. Got <NavLink to="/">Questions?</NavLink></p>
                    </div>
                    <div className="header-summary">
                        <p className="title-7"> Let's <NavLink to="/">get in touch</NavLink> </p>
                    </div>
                </nav>
                <div className="nav-menu" onClick={toggleMenu}>
                    {menuIcon}
                </div>
            </div>
        </header>
    );
}

export default Header;
