import React from 'react'
import ClientsLogosCarousel from './ClientsLogosCarousel'

const ClientsLogos = () => {
  return (
    <div className='clients-logo clients-logo-container'>
        <ClientsLogosCarousel/>
        <div className='title-6'>and over 100 others worldwide</div>
    </div>
  )
}

export default ClientsLogos