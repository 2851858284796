// src/stores/cursorStore.js
import { makeAutoObservable } from 'mobx';

class CursorStore {
  isActive = false;
  activeSize = 400;
  type = "normal";
  videoPlaying = false;
  action = null;
  cursorText = "";
  visible = false

  constructor() {
    makeAutoObservable(this);
  }

  setIsActive(value) {
    this.isActive = value;
  }
  setActiveSize(value) {
    this.activeSize = value;
  }
  setType(value) {
    this.type = value;
  }
  setVideoPlaying(value) {
    this.videoPlaying = value;
  }
  setCursorText(value){
    this.cursorText = value
  }
  setVisible(value){
    this.visible = value
  }
  setAction(value) {
    if (typeof value === 'function' || value === null) {
      this.action = value;
    } else {
      console.warn('Invalid action type. Expected a function or null.');
    }
  }
  executeAction() {
    if (this.action && typeof this.action === 'function') {
      this.action();
    }
  }
}

const cursorStore = new CursorStore();
export default cursorStore;