import React, { useRef, useEffect } from "react";
import "./WithUs.css";
import Lottie from "lottie-react";
const WithUsCard = (props) => {
    const lottieRef = useRef();

    useEffect(() => {
        if (props.isActive) {
            lottieRef.current?.play();
        } else {
            lottieRef.current?.stop();
        }
    }, [props.isActive]);
    return (
        <div className="with-us-card with-us-card-container">
            <div className="image">
                <Lottie
                 lottieRef={lottieRef}
                 animationData={props.image}
                 loop={false}
                 autoplay={false}
                />
            </div>
            <h3 className="title-5 card-title">{props.title}</h3>
            <p
                className="p-4 card-description"
                dangerouslySetInnerHTML={{ __html: props.description }}></p>
        </div>
    );
};

export default WithUsCard;
