import React from "react";
import "./WhyUs.css";
import Card1Image from "./assets/1.png";
import Card2Image from "./assets/2.png";
import Card3Image from "./assets/3.png";
import WhyUsCard from "./WhyUsCard";

const WhyUs = () => {
    const cardData = [
        {
            title: "Clear communication",
            description:
                "Our custom portal streamlines communication, payment, and timelines, keeping everything transparent and on track.",
            image: Card1Image,
            alt: "Clear communication",
        },
        {
            title: "Lightning-fast delivery",
            description:
                "We promise a 24-48 hour turnaround for basic requests, ensuring your projects move forward without delay.",
            image: Card2Image,
            alt: "Clear communication",
        },
        {
            title: "Reasonable pricing ",
            description:
                "Our transparent pricing model means no hidden costs, just reliable, high-quality design services at a fair price.",
            image: Card3Image,
            alt: "Clear communication",
        },
    ];

    return (
        <div className="why-us why-us-container">
            <h2 className="title-2">Why us?</h2>
            <div className="why-us-cards-container">
                {cardData.map((card, index) => (
                    <WhyUsCard
                        title={card.title}
                        description={card.description}
                        image={card.image}
                        alt={card.alt}
                        number={index+1}
                        key={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default WhyUs;
