import React, { useState, useRef } from "react";
import BulletIcon from "./assets/bullet.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import img1 from "../../images/sample/1.jpg";
import img2 from "../../images/sample/2.jpg";
import img3 from "../../images/sample/3.jpg";
import img4 from "../../images/sample/4.jpg";
import img5 from "../../images/sample/5.jpg";
import img6 from "../../images/sample/6.jpg";
import img7 from "../../images/sample/7.jpg";
import img8 from "../../images/sample/8.jpg";
import img9 from "../../images/sample/9.jpg";
import img10 from "../../images/sample/10.jpg";

const ServicesTabs = () => {
  const [activeCard, setActiveCard] = useState(0);
  const parentSwiperRef = useRef(null);

  const cardData = [
    {
      title: "Branding",
      items: ["Logo", "Brand guides", "Stationery", "Brand strategy"],
      banner: [img6, img1, img10, img7, img5],
    },
    {
      title: "UI/UX",
      items: [
        "Website/app",
        "Design systems",
        "No-code frontend",
        "Design audits",
      ],
      banner: [img3, img4, img9, img2, img8],
    },
    {
      title: "Product Packaging",
      items: [
        "Product labels",
        "Food packaging",
        "Subscription boxes",
        "Copywriting",
      ],
      banner: [img1, img10, img7, img5, img6],
    },
    {
      title: "Marketing",
      items: [
        "Marketing",
        "Email graphics",
        "Advertisement design",
        "Brand strategy",
      ],
      banner: [img3, img4, img9, img2, img8],
    },
  ];

  const handleCardClick = (index) => {
    setActiveCard(index);
    if (parentSwiperRef.current) {
      parentSwiperRef.current.slideTo(index);
    }
  };

  return (
    <div className="services-tabs services-tabs-container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        onSwiper={(swiper) => {
          parentSwiperRef.current = swiper;
        }}
        onSlideChange={(swiper) => setActiveCard(swiper.activeIndex)}
        className="parent-swiper"
        navigation
      >
        {cardData.map((card, index) => (
          <SwiperSlide key={index}>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 3000,
              }}
              modules={[Autoplay, EffectFade]}
              effect="fade"
              className="banner-swiper"
            >
              {card.banner.map((imgSrc, imgIndex) => (
                <SwiperSlide key={imgIndex}>
                  <img className="banner" src={imgSrc} alt={`banner-${imgIndex}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="cards-container">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`card card-${index + 1} ${activeCard === index ? "active" : ""}`}
            onClick={() => handleCardClick(index)}
          >
            <div
              className="active-bar"
              style={{ opacity: activeCard === index ? 1 : 0 }}
            ></div>
            <h3 className="title-5">{card.title}</h3>
            {card.items.map((item, itemIndex) => (
              <p key={itemIndex} className="body-1">
                <img className="bullet" src={BulletIcon} alt="bullet" />
                {item}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesTabs;
