import React, { useState, useEffect } from "react";
import "./Error404.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import error4 from "../images/page-assets/404-4.svg";
import Dragon from "../images/page-assets/404-dragon.svg";
import bgimg from "../images/page-assets/404-bg.jpg";
import { NavLink } from "react-router-dom";
import { ButtonPrimarySecond } from "../components/Buttons/PrimaryButton";

const Error404 = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setMousePosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <div className="error404">
            <div
                className="light-me"
                style={{
                    background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, transparent 10%, rgba(0, 0, 0, 0.95) 20%)`,
                }}
            />
            <img src={bgimg} alt="bgimg" className="bgimg" />
            <Header />
            <div className="content">
                <div className="image-box">
                    <img src={error4} alt="Dragon" height={500} width={500} />
                    <img src={Dragon} alt="Dragon" height={500} width={500} className="error-0"/>
                    <img src={error4} alt="Dragon" height={500} width={500} />
                </div>
                <p className="title-4">Page Not Found</p>
                <NavLink to='/'>
                    <ButtonPrimarySecond text="Get Back To Home" />
                </NavLink>
            </div>
            <Footer />
        </div>
    );
};

export default Error404;
