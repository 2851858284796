import sampleVideo from "./assets/sample_video.mp4";
import React, { useRef, useState, useEffect } from "react";
import cursorStore from "../../stores/cursorStore";
import playBtn from "./assets/play-btn.svg";
import "./VideoPlayer.css";
const VideoPlayer = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch((error) => {
                    console.error("Error trying to play the video:", error);
                });
            }
            setIsPlaying(!isPlaying);
            cursorStore.setActiveSize(0);
        }
    };
  
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.onplay = () => console.log("Video is playing");
            video.onpause = () => console.log("Video is paused");
            video.onerror = (e) => console.error("Video error:", e);
        }
    }, []);

    return (
        <div className="video-player">
            <div
                className="video-section"
                onMouseOver={() => {
                    cursorStore.setVisible(true);
                    cursorStore.setIsActive(true);
                    cursorStore.setActiveSize(112);
                    cursorStore.setType("video");
                    cursorStore.setVideoPlaying(isPlaying);
                    cursorStore.setAction(togglePlay);
                }}
                onMouseLeave={() => {
                    cursorStore.setVisible(false);
                    cursorStore.setIsActive(false);
                    cursorStore.setType("normal");
                    cursorStore.setAction(null);
                }}
                onClick={togglePlay}>
                <img
                    src={playBtn}
                    width={32}
                    height={32}
                    alt=""
                    className="playBtn mobile"
                    onClick={togglePlay}
                />
                <video
                    ref={videoRef}
                    className="video"
                    src={sampleVideo}
                    loop
                    controls={false}
                    autoPlay={false}
                    muted={true}></video>
            </div>
        </div>)
}

export default VideoPlayer;