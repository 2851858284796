import React, { useState, useEffect } from "react";
import { ButtonSecondarySecond } from "../Buttons/SecondaryButton";

// Custom hook to get the window width
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
};

const specialStyles = `
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: -0.14px !important;
  text-wrap: nowrap;
  text-transform: capitalize !important;
`;

const ReferAFriend = () => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768; // Adjust this breakpoint as needed
    return (
        <div className="told-your-friends-flex-container">
            <div className="told-your-friends-text-container">
                <h3 className="title-3 told-your-friends-title">
                    Told your friends about us?
                </h3>
                <p className="told-your-friends-subtitle title-7">
                    Make sure to claim your{" "}
                    <span className="subtitle-highlighted-text">
                        2.5% monthly
                    </span>{" "}
                    recurring commission
                </p>
            </div>
            <div className="told-your-friends-button-wrapper">
                <ButtonSecondarySecond
                    customMargin={{ margin: "0px" }}
                    text="Refer a friend"
                    width={isMobile ? "10.1875rem" : "11.25rem"}
                    customStyles={isMobile ? specialStyles : undefined}
                />
            </div>
        </div>
    );
};

export default ReferAFriend;
