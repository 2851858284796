import React from 'react'
import "./WithUs.css"
import WithUsCards from './WithUsCards'

const WithUs = () => {
  return (
    <div className='with-us with-us-container'>
        <h2 className='title-2'>With us, it’s just easy</h2>
        <h3 className='title-5'>Discovery call  →  Immediate onboarding  →  Instant kickoff</h3>
        <WithUsCards/>
    </div>
  )
}

export default WithUs