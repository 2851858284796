import React, { useState } from "react";
import tickmark from "./assets/tickmark.svg";
import { ButtonPrimaryThird } from "../Buttons/PrimaryButton";
import { ButtonSecondarySecond } from "../Buttons/SecondaryButton";
import CardTwoSeperator from "./assets/card-two-seperator.svg";

const pricingCardThreeFeatures = [
    "Unlimited design requests",
    "Unlimited revisions",
    "Pause feature",
    "24-hour avg. delivery time",
    "Dedicated design team",
    "Easy cancellation",
];

const PricingCardThree = () => {
    const [isEssential, setIsEssential] = useState(true);

    const handleToggle = () => {
        setIsEssential(!isEssential);
    };

    return (
        <div className={`pricing-card-two-container ${isEssential ? 'essential-background' : 'pro-background'}`}>
            <div className="pricing-card-three-title-container">
                <h3 className="title-6 pricing-card-three-title">
                    Subscription
                </h3>
                <div className="toggle-container">
                    <input
                        type="checkbox"
                        id="toggle"
                        className="toggle-input"
                        checked={isEssential}
                        onChange={handleToggle}
                    />
                    <label htmlFor="toggle" className="toggle-label">
                        <span
                            className={`toggle-option ${
                                isEssential ? "active" : "inactive"
                            }`}>
                            Essential
                        </span>
                        <span
                            className={`toggle-option ${
                                !isEssential ? "active" : "inactive"
                            }`}>
                            Pro
                        </span>
                    </label>
                </div>
            </div>
            <p className={`pricing-card-two-price body-2 ${!isEssential ? 'pro-text' : ''}`}>
                <span className="pricing-card-two-price-text title-2">
                    {isEssential ? "$2500/m" : "$4000/m"}
                </span>{" "}
                Billed Monthly
            </p>
            <p className={`pricing-card-three-disclaimer body-2 ${!isEssential ? 'pro-text' : ''}`}>
                {isEssential ? "1 request at a time." : "2 requests at a time."}
            </p>
            <p className={`p-2 pricing-card-two-suggestion ${!isEssential ? 'pro-text' : ''}`}>
                Exclusively for existing clients, prior collaborators or
                startups
            </p>
            <img
                className="pricing-card-two-line"
                src={CardTwoSeperator}
                alt="Seperator"
            ></img>
            <div className={`pricing-card-three-feature-container ${!isEssential ? 'pro-text' : ''}`}>
                {pricingCardThreeFeatures.map((featureText) => (
                    <div key={featureText} className="pricing-card-feature">
                        <img
                            className="pricing-card-tickmark"
                            src={tickmark}
                            alt="checkmark"
                        />
                        <p className="p-3">{featureText}</p>
                    </div>
                ))}
            </div>
            <p className={`pricing-card-three-info ${!isEssential ? 'pro-text' : ''}`}>
                (Plan scalable as per requirements)
            </p>
            <div className="pricing-card-button-wrapper">
                <ButtonSecondarySecond
                    customMargin={{ margin: "0px" }}
                    text="Get started"
                    width="100%"
                />
            </div>
        </div>
    );
};

export default PricingCardThree;