import { NavLink } from "react-router-dom";
import "./HeroBanner.css";
import heroBg from "./assets/background.svg";
import heroBgMob from "./assets/backgroundMob.svg";
import desktopArrow1 from "./assets/Arrow_1.svg";
import desktopArrow2 from "./assets/Arrow_2.svg";
import desktopArrow3 from "./assets/Arrow_3.svg";
import cursorStore from "../../stores/cursorStore";
import deviceStore from "../../stores/deviceStore";

import { ButtonPrimarySecond } from "../Buttons/PrimaryButton";
import { observer } from "mobx-react-lite";
const HeroBanner = observer(() => {
    return (
        <div className="hero-banner hero-banner-container">
            <div className="info-container">
                <div className="test-container">
                    <h1
                        className="title-1"
                        onMouseOver={() => {
                            cursorStore.setIsActive(true);
                            cursorStore.setActiveSize(200);
                        }}
                        onMouseLeave={() => cursorStore.setIsActive(false)}>
                        We spent 42 minutes on this quote
                    </h1>
                    <p className="default message-1">
                        transparency <br /> builds trust
                    </p>
                    <p className="default message-2">
                        specificity = humor = <br />
                        emotional connection
                    </p>
                    <p className="default message-3">
                        good hook is most important <br></br>
                        (avg. attention span - 8.25s)
                    </p>
                    <img src={desktopArrow1} alt="arrow" className="img-1" />
                    <img src={desktopArrow2} alt="arrow" className="img-2" />
                    <img src={desktopArrow3} alt="arrow" className="img-3" />
                </div>
                <h2 className="title-5">
                    Because we care about more than just good visuals.
                </h2>
                <div className="p-1 description">
                    At The House of Aster, we start our work beyond the visible.
                    We focus on the intangible aspects—researching, analyzing,
                    understanding, and strategizing—before we dive into creating
                    stunning visuals for your brand. We partner with you through
                    design, to achieve your brand’s goals in every way we can!
                    Read more about our process and vision here.
                </div>
                <ButtonPrimarySecond text="work with us" small />
            </div>
            <img
                src={deviceStore.deviceType === 1 ? heroBg : heroBgMob}
                alt="background"
                className="bg"
            />
        </div>
    );
});

export default HeroBanner;
