// src/stores/deviceStore.js
import { makeAutoObservable } from 'mobx';

class DeviceStore {
  deviceType = 1; // Default to larger viewport
  viewportWidth = window.innerWidth; // Initialize with current viewport width

  constructor() {
    makeAutoObservable(this);
    this.updateDeviceType = this.updateDeviceType.bind(this);
    
    // Initialize the device type and viewport width
    this.updateDeviceType();
    
    // Add event listener for resize
    window.addEventListener('resize', this.updateDeviceType);
  }

  updateDeviceType() {
    this.viewportWidth = window.innerWidth;
    this.deviceType = this.viewportWidth > 768 ? 1 : 2;
  }

  get isLargeViewport() {
    return this.deviceType === 1;
  }

  get isSmallViewport() {
    return this.deviceType === 2;
  }

  // Cleanup method to remove event listener
  cleanup() {
    window.removeEventListener('resize', this.updateDeviceType);
  }
}

const deviceStore = new DeviceStore();
export default deviceStore;