import './App.css';
import './stylesheet/custom.css'
import './stylesheet/typography.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Construction from './pages/Construction';
import Error404 from './pages/Error404';
import Careers from './pages/Careers';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/construction" element={<Construction/>} />
          <Route path="/careers" element={<Careers/>} />

          <Route path="/*" element={<Error404 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;