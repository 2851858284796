import React from "react";
import "./AlwaysHiring.css";

const AlwaysHiring = () => {
  return (
    <div className="always-hiring always-hiring-container">
      <h1 className="title-2">We’re always hiring!</h1>
      <h3 className="title-5">
        Our doors are always open for talented, creative, driven people with a
        growth mindset.
      </h3>
      <p className="p-2 description">
        Members of The House of Aster are characterized by their immense love
        for design and a passion for their craft. We are looking for the best of
        the best in each discipline. If you are excited about the prospect of
        growing with a dynamic team, we look forward to hearing from you!{" "}
        <a href="mailto:join@houseofaster.com" className="email-link">
          join@houseofaster.com
        </a>
      </p>
      <div className="info-box">
        <p className="p-2">
          Aspiring apprentice? We don't hire apprentices (interns) through a
          formal process.
        </p>
        <p className="p-2">
          Just email us your best work - if we love it, you'll hear back with an
          irresistible offer!
        </p>
      </div>
    </div>
  );
};

export default AlwaysHiring;
