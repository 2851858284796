import React, { useEffect, useRef, useState, useCallback } from 'react';
import { gsap } from 'gsap';
import "./Challenges.css";
import { throttle } from 'lodash'; 

const CHALLENGES = [
  "Slow delivery of requests",
  "Hiring headache",
  "Inconsistency in design",
  "Unstructured workflow",
  "Dissatisfying results"
];

const ITEM_HEIGHT = 80;
const SPACING = 8;

const ChallengesScroller = () => {
  const containerRef = useRef(null);
  const itemsRef = useRef([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const updateItems = useCallback(() => {
    const totalItems = CHALLENGES.length;
    const middleIndex = (totalItems - 1) / 2;

    itemsRef.current.forEach((item, index) => {
      if (!item) return;

      let adjustedIndex = (index - scrollPosition + totalItems) % totalItems;
      const itemPosition = adjustedIndex - middleIndex;
      const distanceFromCenter = Math.abs(itemPosition);

      let opacity, scale, zIndex, className;

      if (distanceFromCenter < 0.5) {
        [opacity, scale, zIndex, className] = [1, 1.1, 3, 'center-card'];
      } else if (distanceFromCenter < 1.5) {
        [opacity, scale, zIndex, className] = [0.8, 1, 2, 'near-card'];
      } else {
        [opacity, scale, zIndex, className] = [0.4, 0.9, 1, 'far-card'];
      }

      gsap.to(item, { 
        opacity, 
        scale, 
        zIndex, 
        y: itemPosition * (ITEM_HEIGHT + SPACING),
        duration: 0.5,
        ease: "power2.out",
        onComplete: () => setIsAnimating(false) 
      });
      item.className = `challenge-item ${className}`;
    });
  }, [scrollPosition]);

  const handleScroll = useCallback(throttle((delta) => {
    if (isAnimating) return; 
    setIsAnimating(true);
    setScrollPosition((prev) => (prev + delta + CHALLENGES.length) % CHALLENGES.length);
  }, 200), [isAnimating]);

  useEffect(() => {
    const container = containerRef.current;
    gsap.set(container, { height: `450px` });

    const handleWheel = (e) => {
      e.preventDefault();
      const delta = Math.sign(e.deltaY);
      handleScroll(delta);
    };

    container.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [handleScroll]);

  useEffect(() => {
    updateItems();
  }, [scrollPosition, updateItems]);

  return (
    <>
      <div
        ref={containerRef}
        className="challenges-scroller challenges-scroller-container"
      >
        <div className="challenges-overlay"></div>
          {CHALLENGES.map((challenge, index) => (
            <div
              key={index}
              ref={(el) => (itemsRef.current[index] = el)}
              className="challenge-item"
            >
              {challenge}
            </div>
          ))}
        
      </div>
    </>
  );
};

export default ChallengesScroller;