import React, {useRef, useState} from "react";
import "./CareersHero.css";
import cursorStore from "../../stores/cursorStore";
import sampleVideo from "./assets/sample_video.mp4";
import playBtn from "./assets/play-btn.svg";

const CareersHero = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch((error) => {
                    console.error("Error trying to play the video:", error);
                });
            }
            setIsPlaying(!isPlaying);
            cursorStore.setActiveSize(0);
        }
    };
    return (
        <div className="careers-hero careers-hero-container" >
            <h1 className="title-2">Want to work on something great?</h1>
            <h3 className="title-5">
                Join us on our mission to bring Bharat back on the map as
                innovators of design.{" "}
            </h3>
            <div
                className="video-section"
                onMouseOver={() => {
                    cursorStore.setVisible(true)
                    cursorStore.setIsActive(true);
                    cursorStore.setActiveSize(112);
                    cursorStore.setType("video");
                    cursorStore.setVideoPlaying(isPlaying);
                    cursorStore.setAction(togglePlay);
                }}
                onMouseLeave={() => {
                    cursorStore.setIsActive(false);
                    cursorStore.setType("normal");
                    cursorStore.setAction(null);
                    cursorStore.setVisible(false)
                }}
                onClick={togglePlay}>
                {/* <div className="playBtn desktop" onClick={togglePlay}>
                        <p className="body-1">{isPlaying ? "Pause" : "Play"}</p>
                    </div> */}
                <img
                    src={playBtn}
                    width={32}
                    height={32}
                    alt=""
                    className="playBtn mobile"
                    onClick={togglePlay}
                />
                <video
                    ref={videoRef}
                    className="video"
                    src={sampleVideo}
                    loop
                    controls={false}
                    autoPlay={false}
                    muted={true}></video>
            </div>
        </div>
    );
};

export default CareersHero;
