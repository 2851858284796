import React, { useRef, useEffect, useState } from 'react';
import './FAQ.css';
import plus from '../../images/icons/plus-icon.svg';
import bgImg from '../../images/base-bg.png'

const FAQ = () => {
    const faqData = [
        {
            summary: "Why shouldn’t I hire a full time designer instead?",
            text: "With our subscription service, we offer unlimited revisions until you love every little detail. While a one-off contract is more time-bound, we do take feedback and adjust the designs to your liking as best we can."
        },
        {
            summary: "How quickly will I receive my designs?",
            text: "With our subscription service, we offer unlimited revisions until you love every little detail. While a one-off contract is more time-bound, we do take feedback and adjust the designs to your liking as best we can."
        },
        {
            summary: "What if I’m not happy with the designs?",
            text: "With our subscription service, we offer unlimited revisions until you love every little detail. While a one-off contract is more time-bound, we do take feedback and adjust the designs to your liking as best we can."
        },
        {
            summary: "Can I get a refund?",
            text: "With our subscription service, we offer unlimited revisions until you love every little detail. While a one-off contract is more time-bound, we do take feedback and adjust the designs to your liking as best we can."
        },
        {
            summary: "How do I get a subscription?",
            text: "With our subscription service, we offer unlimited revisions until you love every little detail. While a one-off contract is more time-bound, we do take feedback and adjust the designs to your liking as best we can."
        },
        {
            summary: "Is there any work you don’t do?",
            text: "With our subscription service, we offer unlimited revisions until you love every little detail. While a one-off contract is more time-bound, we do take feedback and adjust the designs to your liking as best we can."
        },
    ];

    const [openIndex, setOpenIndex] = useState(null);
    const contentRefs = useRef([]);

    useEffect(() => {
        contentRefs.current.forEach((content, index) => {
            if (index === openIndex) {
                content.style.maxHeight = `${content.scrollHeight}px`;
            } else {
                content.style.maxHeight = '0px';
            }
        });
    }, [openIndex]);

    const handleToggle = (index) => {
        setOpenIndex(prevIndex => prevIndex === index ? null : index);
    };

    return (
        <div className="FAQ">
            <img src={bgImg} alt="bg" className="bg-img" />
            <div className="FAQ-container">
                <div className="title">
                    <h2 className="title-3">Got Questions?</h2>
                    <h3 className="title-6">Ask Away!</h3>
                </div>
                <div className="qa">
                    {faqData.map((item, index) => (
                        <div className="faq-item" key={index}>
                            <div 
                                className={`faq-summary ${openIndex === index ? 'open' : ''}`}
                                onClick={() => handleToggle(index)}
                            >
                                <p className="title-7">{item.summary}</p>
                                <img src={plus} width={24} height={24} alt='plus-icon' />
                            </div>
                            <div 
                                className="faq-content" 
                                ref={el => contentRefs.current[index] = el}
                            >
                                <p className="p-2">{item.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FAQ;