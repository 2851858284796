import React, { useState, useEffect, useRef } from "react";
import "./Services.css";
import ServicesTabs from "./ServicesTabs";
import ServicesCards from "./ServicesCards";
import { gsap } from "gsap";
import deviceStore from "../../stores/deviceStore";

// Custom hook to get the window width
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};

const Services = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 1000; // Adjust this breakpoint as needed

  const [isHovering, setIsHovering] = useState(false);
  const canTextRef = useRef(null);
  const thaDoRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    if (deviceStore.viewportWidth < 1000 && sectionRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setTimeout(() => {
              animateText("can't");
            }, 2500); // 2.5 seconds delay
          }
        },
        { threshold: 0.5 } // Trigger when 50% of the section is visible
      );

      observer.observe(sectionRef.current);

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, [deviceStore.viewportWidth]);

  const animateText = (newText) => {
    const element = canTextRef.current;
    const thaDoElement = thaDoRef.current;
  
    const tl = gsap.timeline();
  
    tl.to(element, {
      duration: 0.4,
      opacity: 0,
      scale: 0.9,
      ease: "power2.inOut",
      onComplete: () => {
        element.textContent = newText;
      }
    })
    .to(element, {
      duration: 0.4,
      opacity: 1,
      scale: 1,
      ease: "power2.out",
    }, ">-0.2") 
  

    .to(thaDoElement, {
      duration: 0.8,
      x: newText === "can't" ? 8 : 0,
      ease: "power2.inOut",
    }, 0); 
    
    tl.play();
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
    animateText("can't");
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    animateText("can");
  };
  return (
    <div className="services services-container" ref={sectionRef}>
      <div className="title-container">
        <h2 className="title-2">
          What <span ref={canTextRef} className="can-text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>can</span> <span ref={thaDoRef} className="tha-do-text">THA do ?</span>
        </h2>
        <p className="p-2">
          Whether it's eye catching packages, a conversation starting logo, or a
          stunning website - we do it all. You do what you do best, and we'll
          make sure you look good doing it!
        </p>
      </div>
      {deviceStore.viewportWidth < 1000 ? (
        <ServicesCards />
      ) : (
        <ServicesTabs />
      )}
    </div>
  );
};

export default Services;
