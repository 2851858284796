import React from 'react'
import ChallengesScroller from './ChallengesScroller'

const Challenges = () => {
  return (
    <div className='challenges challenges-container'>
        <h2 className='title-2'>Running a company is hard</h2>
        <h3 className='title-5'>Why make it harder with...</h3>
        <ChallengesScroller/>

    </div>
  )
}

export default Challenges