import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import bgImg from "../images/page-assets/bg-yellow.jpg";
import mainLogo from '../images/page-assets/construction-outline.png';
import Logo1 from "../images/page-assets/THA.png";
import Logo2 from "../images/page-assets/Shloka.png";
import Logo3 from "../images/page-assets/Kubhera.png";
import celebrate from "../images/page-assets/celebrate.gif";
import { ButtonPrimarySecond } from "../components/Buttons/PrimaryButton";
import { NavLink } from "react-router-dom";
import './Construction.css';
import React, { useRef, useEffect, useCallback, useState } from 'react';

const Construction = () => {
    const logo1Ref = useRef(null);
    const logo2Ref = useRef(null);
    const logo3Ref = useRef(null);
    const mainLogoRef = useRef(null); 
    const celebrateRef = useRef(null);
     // eslint-disable-next-line 
    const [counter, setCounter] = useState(0);

    const makeElementDraggable = useCallback((element, targetX, targetY, range) => {
        let posX = 0, posY = 0, startX = 0, startY = 0;

        const elementDrag = (e) => {
            e.preventDefault();
            const clientX = e.clientX || e.touches[0].clientX;
            const clientY = e.clientY || e.touches[0].clientY;
            posX = startX - clientX;
            posY = startY - clientY;
            startX = clientX;
            startY = clientY;
            element.style.top = (element.offsetTop - posY) + "px";
            element.style.left = (element.offsetLeft - posX) + "px";

            // Snap to position within range
            if (Math.abs(element.offsetTop - targetY) < range && Math.abs(element.offsetLeft - targetX) < range) {
                element.style.top = targetY + "px";
                element.style.left = targetX + "px";
                element.classList.add('active');
                closeDragElement();
                setCounter(prevCounter => {
                    const newCounter = prevCounter + 1;
                    if (newCounter % 3 === 0 && mainLogoRef.current) {
                        mainLogoRef.current.classList.add('active-shadow');
                        celebrateRef.current.style.display = "block";
                    }
                    return newCounter;
                });
            } else {
                element.classList.remove('active');
            }
        };

        const closeDragElement = () => {
            document.onmouseup = null;
            document.onmousemove = null;
            document.ontouchend = null;
            document.ontouchmove = null;
        };

        const startDragElement = (e) => {
            e.preventDefault();
            startX = e.clientX || e.touches[0].clientX;
            startY = e.clientY || e.touches[0].clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
            document.ontouchend = closeDragElement;
            document.ontouchmove = elementDrag;
        };

        element.onmousedown = startDragElement;
        element.ontouchstart = startDragElement;
    }, []);

    const makeLogoDraggable = useCallback((element, targetX, targetY, range) => {
        makeElementDraggable(element, targetX, targetY, range);
    }, [makeElementDraggable]);

    useEffect(() => {
        const applyDraggablePositions = () => {
            const screenWidth = window.innerWidth;
            let targetPositions;

            if (screenWidth < 750) {
                targetPositions = {
                    logo1: [22, 25, 35],
                    logo2: [60, 185, 40],
                    logo3: [63, 63, 45],
                };
            } else if (screenWidth < 850) {
                targetPositions = {
                    logo1: [34, 33, 15],
                    logo2: [150, 150, 20],
                    logo3: [88, 88, 25],
                };
            } else {
                targetPositions = {
                    logo1: [33, 32, 15],
                    logo2: [85, 259, 20],
                    logo3: [88, 88, 25],
                };
            }

            if (logo1Ref.current) makeLogoDraggable(logo1Ref.current, ...targetPositions.logo1);
            if (logo2Ref.current) makeLogoDraggable(logo2Ref.current, ...targetPositions.logo2);
            if (logo3Ref.current) makeLogoDraggable(logo3Ref.current, ...targetPositions.logo3);
        };

        applyDraggablePositions();
        window.addEventListener('resize', applyDraggablePositions);

        return () => {
            window.removeEventListener('resize', applyDraggablePositions);
        };
    }, [makeLogoDraggable]);

    return (
        <><Header />
        <div className="construction">
            <img src={bgImg} alt="bgimg" className="bgimg" />
            <div className="contents">
                <div className="info">
                    <p className="title-5">This Page is </p>
                    <h1 className="title-1">Under Construction</h1>
                    <NavLink to="/">
                        <ButtonPrimarySecond text="Go Back to Home" />
                    </NavLink>
                </div>
                <div className="image-box">
                    <div className="logo">
                        <img ref={mainLogoRef} src={mainLogo} width={400} height={400} alt="logo" className="main-logo" />
                        <img ref={logo1Ref} src={Logo1} width={200} height={200} alt="logo" className="logo1" />
                        <img ref={logo2Ref} src={Logo2} width={200} height={200} alt="logo" className="logo2" />
                        <img ref={logo3Ref} src={Logo3} width={200} height={200} alt="logo" className="logo3" />
                        <img ref={celebrateRef} src={celebrate} width={350} height={350} alt="Celebration Gif" className="celebrate" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
};

export default Construction;
