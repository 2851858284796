import image from "./assets/image-1.png";
import "./HouseCulture.css"
const HouseCulture = ()=>
{
    return(<div className="house-culture">
        <div className="content">
            <div className="title">
                <h2 className="title-2">
                     House culture
                </h2>
                <p className="p-2">
                At The House of Aster, we believe in nurturing talent and providing an environment where creativity can flourish. We strive for individual professional growth that carries forward, beyond just your work at THA. 
                <br /> <br />
                The House is young, energetic and open to new ideas. You will find a lot of unconventional reforms in place. We have a strict no-corporate-BS policy! It’s never a dull day with our team :)
                </p>
            </div>
            <div className="image-box">
                <img src={image} alt="House Culture" />
                <p>Members of The House</p>
            </div>
        </div>
    </div>)
}
export default HouseCulture;