import React, { useState, useEffect, useCallback } from "react";
import "./ClientsProblems.css";
import { ButtonSecondarySecond } from "../Buttons/SecondaryButton";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    EffectCoverflow,
    Pagination,
    Navigation,
    Mousewheel,
    Keyboard,
} from "swiper/modules";

import CardImage1 from "./assets/image_1.webp";
import CardImage2 from "./assets/image_1.webp";
import CardImage3 from "./assets/image_1.webp";
import ClientsProblemsCard from "./ClientsProblemsCard";
import cursorStore from "../../stores/cursorStore";
import { observer } from "mobx-react-lite";

// Custom hook to get the window width
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
};

const ClientsProblems = observer(() => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768; // Adjust this breakpoint as needed
    const [swiper, setSwiper] = useState(null);

    const handleMouseEnter = useCallback((text) => {
        cursorStore.setVisible(true);
        cursorStore.setIsActive(true);
        cursorStore.setActiveSize(112);
        cursorStore.setType("slider");
        cursorStore.setCursorText(text);
    }, []);

    const handleMouseLeave = useCallback(() => {
        cursorStore.setVisible(false);
        cursorStore.setIsActive(false);
        cursorStore.setType("normal");
        cursorStore.setCursorText("");
    }, []);
    const cardData = [
        {
            title: "STARTUP VILLAGE",
            subtitle: "UX • BRANDING • WEB DESIGN",
            image: CardImage1,
            alt: "A picture",
            link: "https://google.com",
        },
        {
            title: "STARTUP VILLAGE",
            subtitle: "UX • BRANDING • WEB DESIGN",
            image: CardImage1,
            alt: "A picture",
            link: "https://google.com",
        },
        {
            title: "STARTUP VILLAGE",
            subtitle: "UX • BRANDING • WEB DESIGN",
            image: CardImage1,
            alt: "A picture",
            link: "https://google.com",
        },
        {
            title: "STARTUP VILLAGE",
            subtitle: "UX • BRANDING • WEB DESIGN",
            image: CardImage1,
            alt: "A picture",
            link: "https://google.com",
        },
        {
            title: "STARTUP VILLAGE",
            subtitle: "UX • BRANDING • WEB DESIGN",
            image: CardImage1,
            alt: "A picture",
            link: "https://google.com",
        },
        {
            title: "STARTUP VILLAGE",
            subtitle: "UX • BRANDING • WEB DESIGN",
            image: CardImage1,
            alt: "A picture",
            link: "https://google.com",
        },
    ];

    return (
        <div
            className="clients-problems clients-problems-container"
           >
            <h2 className="title-2">Better done than said</h2>
            <div className="swiper-container">
                <Swiper
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={1}
                    spaceBetween={15}
                    navigation={false} // Disable default navigation
                    pagination={{ enabled: true }}
                    modules={[
                        EffectCoverflow,
                        Pagination,
                        Navigation,
                        Mousewheel,
                        Keyboard,
                    ]}
                    watchSlidesProgress={true}
                    loop={true}
                    effect={isMobile ? "" : "coverflow"}
                    dir="ltr"
                    onSwiper={setSwiper}
                    breakpoints={{
                        768: {
                            slidesPerView: "2.2",
                            centeredSlides: true,
                            spaceBetween: 0,
                            navigation: true,
                            pagination: { enabled: false, clickable: false },
                            centeredSlides: "true",
                            coverflowEffect: {
                                rotate: 20,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: false,
                                scale: 0.8,
                            },
                        },
                        1512: {
                            slidesPerView: "auto",
                            centeredSlides: "true",
                            coverflowEffect: {
                                rotate: 10,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: false,
                                scale: 0.9,
                            },
                        },
                    }}
                    className="clients-problems-cards-container swiper">
                    {cardData.map((item, itemIndex) => (
                        <SwiperSlide key={itemIndex}>
                            {({ isActive, isVisible, isPrev, isNext }) => (
                                <ClientsProblemsCard
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    image={item.image}
                                    alt={item.alt}
                                    link={item.link}
                                    opacity={isActive ? 1 : 0.6}
                                />
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
                {!isMobile && (
                    <>
                        <button
                            className="custom-swiper-button-prev"
                            onClick={() => swiper?.slidePrev()}
                            onMouseEnter={() => handleMouseEnter("prev")}
                            onMouseLeave={handleMouseLeave}></button>
                        <button
                            className="custom-swiper-button-next"
                            onClick={() => swiper?.slideNext()}
                            onMouseEnter={() => handleMouseEnter("next")}
                            onMouseLeave={handleMouseLeave}></button>
                    </>
                )}
            </div>
            <ButtonSecondarySecond text="Explore our work" />
        </div>
    );
});

export default ClientsProblems;
