import React, { useState } from "react";
import { Button } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Keyframes for the growing circle effect
const growCircle = keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  50% {
    width: 200px;
    height: 200px;
    opacity: 0.2;
  }
  100% {
    width: 400px;
    height: 400px;
    opacity: 0;
  }
`;

// Custom styled buttons
const StyledButton = styled(
  ({ customMargin, customStyles, width, ...otherProps }) => (
    <Button {...otherProps} />
  )
)`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  font-family: "Space Grotesk", sans-serif !important;
  padding: 12px 24px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.44);
  backdrop-filter: blur(16px);
  color: white;
  transition: all 300ms ease-in-out;
  font-family: var(--font-family-2), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.16px;
  text-transform: capitalize !important;
  &:hover {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.75);
    background: #feead4;
    color: var(--color-primary);
  }
  margin: ${(props) => props.customMargin || "auto auto"};
  width: ${(props) => props.width || "auto"};

  &.small {
    padding: 12px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.14px;
  }

  &.clicked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: #feead4;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: ${growCircle} 1s forwards;
  }

  ${(props) => props.customStyles}
`;

// External CSS for arrow rotation on hover
const arrowRotation = `
  .rotate-on-hover:hover .MuiSvgIcon-root {
     transform: rotate(-45deg);
  }

  .rotate-on-hover .MuiSvgIcon-root {
    transition: transform 300ms ease;
  }
`;

// Inject external CSS
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = arrowRotation;
document.head.appendChild(styleSheet);

// Button with just text and external arrow
function ButtonSecondaryFirst({
  text,
  small,
  disabled,
  width,
  customMargin,
  customStyles,
}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? "clicked" : ""} ${small ? "small" : ""} ${
        disabled ? "disabled" : ""
      } rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
      width={width}
      customMargin={customMargin}
      customStyles={customStyles}
    >
      {text}
    </StyledButton>
  );
}

// Button with text, external arrow to the right
function ButtonSecondarySecond({
  text,
  small,
  disabled,
  width,
  customMargin,
  customStyles,
}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? "clicked" : ""} ${small ? "small" : ""} ${
        disabled ? "disabled" : ""
      } rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
      width={width}
      customMargin={customMargin}
      customStyles={customStyles}
    >
      {text} <ArrowForwardIcon />
    </StyledButton>
  );
}

// Button with external arrow to the right and then text
function ButtonSecondaryThird({
  text,
  small,
  disabled,
  width,
  customMargin,
  customStyles,
}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? "clicked" : ""} ${small ? "small" : ""} ${
        disabled ? "disabled" : ""
      } rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
      width={width}
      customMargin={customMargin}
      customStyles={customStyles}
    >
      <ArrowForwardIcon /> {text}
    </StyledButton>
  );
}

// Button with just the arrow pointing top right
function ButtonSecondaryFourth({
  small,
  disabled,
  width,
  customMargin,
  customStyles,
}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };

  return (
    <StyledButton
      variant="contained"
      className={`${clicked ? "clicked" : ""} ${small ? "small" : ""} ${
        disabled ? "disabled" : ""
      } rotate-on-hover`}
      onClick={handleClick}
      disabled={disabled}
      width={width}
      customMargin={customMargin}
      customStyles={customStyles}
    >
      <ArrowForwardIcon />
    </StyledButton>
  );
}

export {
  ButtonSecondaryFirst,
  ButtonSecondarySecond,
  ButtonSecondaryThird,
  ButtonSecondaryFourth,
};