import React from "react";
import tickmark from "./assets/tickmark.svg";
import CardTwoSeperator from "./assets/card-two-seperator.svg";
import { ButtonSecondarySecond } from "../Buttons/SecondaryButton";

const PricingCardTwo = () => {
    const features = [
        "Brand personality & tone of voice",
        {
            main: "Visual identity with customizable deliverables",
            subPoints: [
                "Logo + Typography",
                "Color Palette",
                "Stationary",
                "Merch",
                "Brand mockups",
            ],
        },
        "Brand book with assets & guidelines for future requirements",
    ];

    return (
        <div className="pricing-card-two-container">
            <h3 className="title-6 pricing-card-two-title">Branding</h3>
            <p className="pricing-card-two-price body-2">
                <span className="pricing-card-two-price-text title-2">
                    $3000
                </span>{" "}
                onwards
            </p>
            <p className="p-2 pricing-card-two-suggestion">
                Ideal for branding only requirement
            </p>
            <img
                className="pricing-card-two-line"
                src={CardTwoSeperator}
                alt="Separator"
            />
            <div className="pricing-card-two-feature-container">
                {features.map((feature, index) => (
                    <FeatureItem key={index} feature={feature} />
                ))}
            </div>
            <p className="pricing-card-info">
                (Plan customizable to include special requirements such as
                motion graphics)
            </p>
            <div className="pricing-card-button-wrapper">
                <ButtonSecondarySecond
                    customMargin={{ margin: "0px" }}
                    text="Get started"
                    width="100%"
                />
            </div>
        </div>
    );
};

const FeatureItem = ({ feature }) => {
    if (typeof feature === "string") {
        return (
            <div className="pricing-card-feature">
                <img
                    className="pricing-card-tickmark"
                    src={tickmark}
                    alt="checkmark"
                />
                <p className="p-3">{feature}</p>
            </div>
        );
    } else {
        return (
            <div className="pricing-card-feature-with-subpoints">
                <div className="pricing-card-feature">
                    <img
                        className="pricing-card-tickmark"
                        src={tickmark}
                        alt="checkmark"
                    />
                    <p className="p-3">{feature.main}</p>
                </div>
                <ul className="pricing-card-two-subfeatures">
                    {feature.subPoints.map((subPoint, index) => (
                        <li key={index} className="body-2">
                            {subPoint}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
};

export default PricingCardTwo;
